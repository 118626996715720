import { PhoneInputStatus } from './PhoneInput.decl';

import { alpha2ToAlpha3, getName } from 'i18n-iso-countries';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import {
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumber,
  getCountries,
} from 'libphonenumber-js/min';

export function deriveCallingCode(alpha2: string): string {
  return getCountryCallingCode(alpha2 as CountryCode);
}

export function getDerivedFlagAndNationalNumber(
  defaultValue?: string | null,
  value?: string | null,
  defaultFlag?: string
): [string, string] {
  try {
    const number = parsePhoneNumber(
      defaultValue || value || '',
      (defaultFlag || 'FR') as CountryCode
    );
    const country = number.country as CountryCode;

    return [country, formatIncompletePhoneNumber(number.nationalNumber as string, number.country)];
  } catch {
    return [defaultFlag || 'FR', ''];
  }
}

export function getStatusColors(status?: PhoneInputStatus): {
  regular: string;
  focus: string;
  boxShadow: string;
  status?: PhoneInputStatus;
} {
  switch (status) {
    case 'success': {
      return {
        regular: 'primary-500',
        focus: 'primary-600',
        boxShadow: 'primary-600',
        status,
      };
    }

    case 'error': {
      return {
        regular: 'critical-500',
        focus: 'critical-600',
        boxShadow: 'critical-600',
        status,
      };
    }

    default: {
      return {
        regular: 'neutral-200',
        focus: 'text-base',
        boxShadow: 'transparent',
      };
    }
  }
}

export function getSortedCountries(
  lang = 'en'
): { alpha2: string; alpha3: string; name: string; countryCode: string }[] {
  return getCountries()
    .reduce<{ alpha2: string; alpha3: string; name: string; countryCode: string }[]>(
      (acc, alpha2) => {
        const alpha3 = alpha2ToAlpha3(alpha2);

        if (alpha3) {
          acc.push({
            alpha2,
            alpha3,
            name: getName(alpha3, lang) || getName(alpha3, 'en'),
            countryCode: getCountryCallingCode(alpha2),
          });
        }

        return acc;
      },
      []
    )
    .sort((a, b) => a.name?.localeCompare(b.name));
}
