import { ChangeEvent, useMemo, useState } from 'react';

import { PhoneInputMenuProps } from './PhoneInput.decl';
import { getSortedCountries } from './utils';

import {
  Box,
  FlagIcon,
  TextInput,
  ComboBoxMenu,
  ComboBoxItem,
  MenuItemPrefix,
  MenuItemSuffix,
  MenuItemLabel,
} from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export function PhoneInputMenu({ lang }: PhoneInputMenuProps) {
  const { t } = useTranslation();
  const countries = useMemo(() => getSortedCountries(lang), [lang]);
  const [filterText, setFilter] = useState('');

  const filteredCountries = useMemo(
    () =>
      filterText
        ? countries.filter(({ alpha2, alpha3, name, countryCode }) =>
            [
              alpha2.toLowerCase(),
              alpha3.toLowerCase(),
              name.toLowerCase(),
              countryCode.toLowerCase(),
            ].some((checkStr) => checkStr.includes(filterText.toLowerCase()))
          )
        : countries,
    [countries, filterText]
  );

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.currentTarget.value);
  };

  return (
    <Box padding='xs' paddingBottom={0} data-test='phone-input-menu'>
      <TextInput
        data-test='phone-input-filter-input'
        placeholder={t('calls.pages.blocked_numbers.filter_placeholder')}
        size='small'
        onChange={handleFilter}
      />
      <ComboBoxMenu maxHeight='200px' mt='xs'>
        {filteredCountries.map(({ alpha2, name, countryCode }) => (
          <ComboBoxItem key={alpha2} itemKey={alpha2} data-test={`phone-input-menu-item-${alpha2}`}>
            <MenuItemPrefix>
              <FlagIcon countryCode={alpha2} size={25} />
            </MenuItemPrefix>
            <MenuItemLabel>{name}</MenuItemLabel>
            <MenuItemSuffix>+{countryCode}</MenuItemSuffix>
          </ComboBoxItem>
        ))}
      </ComboBoxMenu>
    </Box>
  );
}
