/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect } from 'react';

import { PhoneInput } from './PhoneInput';
import { DynamicDataLoaderProps } from './PhoneInput.decl';

import { registerLocale } from 'i18n-iso-countries';

export function DynamicDataLoader({ lang = 'en', ...rest }: DynamicDataLoaderProps) {
  useEffect(() => {
    registerLocale(require(`i18n-iso-countries/langs/${lang}.json`));
  }, [lang]);

  return <PhoneInput {...rest} lang={lang} />;
}
