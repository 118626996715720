import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { PhoneInputProps, PhoneInputStatus } from './PhoneInput.decl';
import { PhoneInputMenu } from './PhoneInputMenu';
import { getDerivedFlagAndNationalNumber, getSortedCountries, getStatusColors } from './utils';

import { ChevronDownOutlined } from '@aircall/icons';
import { Flex, FlagIcon, ComboBox, ComboBoxContent, ComboBoxTrigger } from '@aircall/tractor-v2';
import styled, { css } from '@xstyled/styled-components';
import { parseDigit } from 'input-format';
import ReactInput from 'input-format/react';
import { AsYouType, CountryCode, getCountryCallingCode } from 'libphonenumber-js/min';
import noop from 'lodash-es/noop';

const InputWrapper = styled(Flex)<{ status?: PhoneInputStatus }>(({ status }) => {
  const { regular, focus, boxShadow } = getStatusColors(status);

  return css`
    width: 100%;
    align-items: center;
    padding: 0 16px;
    border: 1px solid ${regular};
    border-radius: sm;
    background-color: surface-default;
    transition: all 0.25s ease;

    &:focus-within {
      border-color: ${focus};
      box-shadow: 0 0 0 1px ${boxShadow};
    }
  `;
});

const CallingCode = styled.span`
  width: 40px;
  margin-left: 10px;
  text-align: left;
`;

const Input = styled(ReactInput)`
  width: 100%;
  flex-shrink: 1;
  padding: 2px;
  border: 0;
  margin-left: 10px;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  outline: 0;
`;

const inputHeights = {
  small: '40px',
  regular: '48px',
};

export function PhoneInput({
  autoFocus = true,
  defaultValue,
  value,
  placeholder,
  name,
  status,
  defaultFlag = 'FR',
  lang,
  size = 'regular',
  onBlur = noop,
  onChange = noop,
}: PhoneInputProps) {
  const inputHeight = inputHeights[size];

  const [derivedAlpha2, derivedNationalNumber] = useMemo(
    () => getDerivedFlagAndNationalNumber(defaultValue, value, defaultFlag),
    [defaultValue, value, defaultFlag]
  );

  const [country, setCountry] = useState(derivedAlpha2);
  const [number, setNumber] = useState(derivedNationalNumber);

  const callingCode = useMemo(() => getCountryCallingCode(country as CountryCode), [country]);
  const countries = useMemo(() => getSortedCountries(lang), [lang]);

  const handleCountrySelection = useCallback(
    (keys) => {
      setCountry(keys?.[0]);
    },
    [setCountry]
  );

  // effect to validate and send full number in onChange callback
  // this happens every time the country calling code
  // or the number changes.
  useEffect(() => {
    const fullNumber = `+${callingCode}${number}`;
    const asYouType = new AsYouType(country as CountryCode);

    asYouType.input(fullNumber);

    onChange(fullNumber, asYouType.isValid());
  }, [onChange, number, callingCode, country]);

  const handleFormatValue = (valueToFormat: string | undefined) => {
    const newValue = valueToFormat as string;

    const asYouType = new AsYouType(country as CountryCode);
    const text = asYouType.input(newValue);
    const template = asYouType.getTemplate();

    return { text, template };
  };

  const handleParseValue = (character: string, valueToParse: string) => {
    // Leading plus is allowed
    if (character === '+') {
      if (!valueToParse) {
        return character;
      }
    }
    // Digits are allowed
    return parseDigit(character);
  };

  const handleOnChange = (receivedValue: string | ChangeEvent<HTMLInputElement> | undefined) => {
    const newValue = (receivedValue as string).replace(/[^0-9|+]$/gm, '');

    if (newValue.startsWith('+')) {
      const typedCountry = countries.find(
        (countryFromList) => countryFromList.countryCode === newValue.replace(/(\D)+/g, '')
      )?.alpha2;

      if (typedCountry) {
        setCountry(typedCountry);
        setNumber('');
      } else {
        setNumber(newValue);
      }

      return;
    }

    setNumber(newValue);
  };

  return (
    <InputWrapper status={status} data-test='phone-input-wrapper' h={inputHeight}>
      <ComboBox selectionMode='single' onSelectionChange={handleCountrySelection}>
        <ComboBoxTrigger>
          <Flex alignItems='center' cursor='pointer' data-test='phone-input-menu-trigger'>
            <FlagIcon countryCode={country} data-test={`phone-input-flag-${country}`} />
            <CallingCode>+{callingCode}</CallingCode>
            <ChevronDownOutlined size={15} />
          </Flex>
        </ComboBoxTrigger>
        <ComboBoxContent takeTriggerWidth={false}>
          <PhoneInputMenu lang={lang as string} />
        </ComboBoxContent>
      </ComboBox>
      <Input
        onBlur={onBlur}
        data-test='phone-input-input'
        autoFocus={autoFocus}
        placeholder={placeholder}
        value={number}
        onChange={handleOnChange}
        parse={handleParseValue}
        format={handleFormatValue}
      />
      <input
        type='hidden'
        data-test='phone-hidden-input'
        name={name}
        value={`+${callingCode}${number}`}
      />
    </InputWrapper>
  );
}
